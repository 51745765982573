<template>
  <div>
    <SpinnerLoader v-if="inventoryExtendedStatus !== 'success'" :loading="inventoryExtendedStatus" />
    <template v-else-if="inventoryExtendedStatus === 'success' && inventoryExtended">
      <div class="table-responsive mb-0 inventory-extended">
        <table class="table table-sm small table-hover table-bordered ws-nowrap sticky-headers sticky-footer">
          <thead>
            <tr>
              <th rowspan="3">
                <div class="resizable channel">{{ $t('channelModal.channel') }}</div>
              </th>
              <th rowspan="3">
                {{ $t('table.period') }}
              </th>
              <th rowspan="3">
                <div class="resizable target">{{ $t('channelModal.target') }}</div>
              </th>
              <th colspan="9">wGRP</th>
              <th colspan="9">{{ $t('reports.minutes') }}</th>
              <th colspan="3" rowspan="2">{{ $t('reports.openInHour') }}</th>
            </tr>

            <tr>
              <th colspan="3">{{ $t('table.open') }}</th>
              <th colspan="3">{{ $t('reports.soldOut') }}</th>
              <th colspan="3">SOR %</th>

              <th colspan="3">{{ $t('table.open') }}</th>
              <th colspan="3">{{ $t('reports.soldOut') }}</th>
              <th colspan="3">SOT %</th>
            </tr>
            <tr>
              <th class="text-right">Prime</th>
              <th class="text-right">Off-Prime</th>
              <th class="text-right">{{ $t('booking.total') }}</th>

              <th class="text-right">Prime</th>
              <th class="text-right">Off-Prime</th>
              <th class="text-right">{{ $t('booking.total') }}</th>

              <th class="text-right">Prime</th>
              <th class="text-right">Off-Prime</th>
              <th class="text-right">{{ $t('booking.total') }}</th>

              <th class="text-right">Prime</th>
              <th class="text-right">Off-Prime</th>
              <th class="text-right">{{ $t('booking.total') }}</th>

              <th class="text-right">Prime</th>
              <th class="text-right">Off-Prime</th>
              <th class="text-right">{{ $t('booking.total') }}</th>

              <th class="text-right">Prime</th>
              <th class="text-right">Off-Prime</th>
              <th class="text-right">{{ $t('booking.total') }}</th>

              <th class="text-right">Prime</th>
              <th class="text-right">Off-Prime</th>
              <th class="text-right">{{ $t('booking.total') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, gpIndex) in inventoryExtended.items" :key="gpIndex">
              <td name="channel">{{ row.channel_name }}</td>
              <td>{{ row.date_from | convertDate }} - {{ row.date_to | convertDate }}</td>
              <td name="target">{{ row.audience_name }}</td>

              <td class="text-right">{{ row.wgrp_open_prime | toFixedAndSpace }}</td>
              <td class="text-right">{{ row.wgrp_open_off_prime | toFixedAndSpace }}</td>
              <td class="text-right">{{ row.wgrp_open_total | toFixedAndSpace }}</td>

              <td class="text-right">{{ row.wgrp_sold_prime | toFixedAndSpace }}</td>
              <td class="text-right">{{ row.wgrp_sold_off_prime | toFixedAndSpace }}</td>
              <td class="text-right">{{ row.wgrp_sold_total | toFixedAndSpace }}</td>

              <td class="text-right">{{ row.wgrp_sor_prime | toFixedAndSpace }}</td>
              <td class="text-right">{{ row.wgrp_sor_off_prime | toFixedAndSpace }}</td>
              <td class="text-right">{{ row.wgrp_sor_total | toFixedAndSpace }}</td>

              <td class="text-right">{{ row.minutes_open_prime | toFixedAndSpace }}</td>
              <td class="text-right">{{ row.minutes_open_off_prime | toFixedAndSpace }}</td>
              <td class="text-right">{{ row.minutes_open_total | toFixedAndSpace }}</td>

              <td class="text-right">{{ row.minutes_sold_prime | toFixedAndSpace }}</td>
              <td class="text-right">{{ row.minutes_sold_off_prime | toFixedAndSpace }}</td>
              <td class="text-right">{{ row.minutes_sold_total | toFixedAndSpace }}</td>

              <td class="text-right">{{ row.minutes_sot_prime | toFixedAndSpace }}</td>
              <td class="text-right">{{ row.minutes_sot_off_prime | toFixedAndSpace }}</td>
              <td class="text-right">{{ row.minutes_sot_total | toFixedAndSpace }}</td>

              <td class="text-right">{{ row.open_in_hour_prime | toFixedAndSpace }}</td>
              <td class="text-right">{{ row.open_in_hour_off_prime | toFixedAndSpace }}</td>
              <td class="text-right">{{ row.open_in_hour_total | toFixedAndSpace }}</td>
            </tr>
          </tbody>

          <tfoot v-if="inventoryExtended.totals" class="font-weight-bold">
            <tr>
              <th colspan="3">{{ $t('booking.total') }}</th>
              <th class="text-right">{{ inventoryExtended.totals.wgrp_open_prime | toFixedAndSpace }}</th>
              <th class="text-right">{{ inventoryExtended.totals.wgrp_open_off_prime | toFixedAndSpace }}</th>
              <th class="text-right">{{ inventoryExtended.totals.wgrp_open_total | toFixedAndSpace }}</th>

              <th class="text-right">{{ inventoryExtended.totals.wgrp_sold_prime | toFixedAndSpace }}</th>
              <th class="text-right">{{ inventoryExtended.totals.wgrp_sold_off_prime | toFixedAndSpace }}</th>
              <th class="text-right">{{ inventoryExtended.totals.wgrp_sold_total | toFixedAndSpace }}</th>

              <th class="text-right">{{ inventoryExtended.totals.wgrp_sor_prime | toFixedAndSpace }}</th>
              <th class="text-right">{{ inventoryExtended.totals.wgrp_sor_off_prime | toFixedAndSpace }}</th>
              <th class="text-right">{{ inventoryExtended.totals.wgrp_sor_total | toFixedAndSpace }}</th>

              <th class="text-right">{{ inventoryExtended.totals.minutes_open_prime | toFixedAndSpace }}</th>
              <th class="text-right">{{ inventoryExtended.totals.minutes_open_off_prime | toFixedAndSpace }}</th>
              <th class="text-right">{{ inventoryExtended.totals.minutes_open_total | toFixedAndSpace }}</th>

              <th class="text-right">{{ inventoryExtended.totals.minutes_sold_prime | toFixedAndSpace }}</th>
              <th class="text-right">{{ inventoryExtended.totals.minutes_sold_off_prime | toFixedAndSpace }}</th>
              <th class="text-right">{{ inventoryExtended.totals.minutes_sold_total | toFixedAndSpace }}</th>

              <th class="text-right">{{ inventoryExtended.totals.minutes_sot_prime | toFixedAndSpace }}</th>
              <th class="text-right">{{ inventoryExtended.totals.minutes_sot_off_prime | toFixedAndSpace }}</th>
              <th class="text-right">{{ inventoryExtended.totals.minutes_sot_total | toFixedAndSpace }}</th>

              <th class="text-right">{{ inventoryExtended.totals.open_in_hour_prime | toFixedAndSpace }}</th>
              <th class="text-right">{{ inventoryExtended.totals.open_in_hour_off_prime | toFixedAndSpace }}</th>
              <th class="text-right">{{ inventoryExtended.totals.open_in_hour_total | toFixedAndSpace }}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';
import toFixedAndSpace from '@/filters/toFixedAndSpace';
import convertDate from '@/filters/convertDate';

export default {
  name: 'InventoryExtendedTable',
  components: { SpinnerLoader },
  filters: { toFixedAndSpace, convertDate },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      inventoryExtended: 'getReportInventoryExtended',
      inventoryExtendedStatus: 'getReportInventoryExtendedStatus',
      expandAllRows: 'getExpandAllRows',
    }),
  },
  destroyed() {
    this.$store.commit('clearReportInventoryExtended');
  },
  methods: {},
};
</script>

<style lang="sass">
.reports-page div.table-responsive.inventory-extended
  height: calc(100vh - 210px)

  table.table
    div.resizable
      &.channel,
      &.target
        min-width: 100px

    td[name="channel"],
    td[name="target"]
      max-width: 100px

@media (min-width: 2085px)
  .reports-page div.table-responsive.inventory-extended
    height: calc(100vh - 154px)
</style>
